import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

const CONFIG: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_BASE_URL,
  responseType: "json",
//   withCredentials: true,
};

export class API {
  private api: AxiosInstance;

  constructor(config: AxiosRequestConfig) {
    this.api = axios.create(config);
  }

  public async get(url: string, params: object = {}, headers: object = {}) {
    return this.api.get(url, {
      params,
      headers,
    });
  }

  public async post(url: string, data: object = {}, params: object = {}, headers: object = {}) {
    return this.api.post(url, data, {
      params,
      headers,
    });
  }

  public async put(url: string, data: object = {}, params: object = {}, headers: object = {}) {
    return this.api.put(url, {
      data,
      params,
      headers,
    });
  }

  public async delete(url: string, data: object = {}, params: object = {}) {
    return this.api.delete(url, {
      data,
      params,
    });
  }
}

export default new API(CONFIG);
