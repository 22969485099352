import { useEffect, useState } from "react";
import api from "./service/api";
import { Table, message } from "antd";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Language",
    dataIndex: "language",
    key: "language",
  },
  {
    title: "Bio",
    dataIndex: "bio",
    key: "bio",
  },
];

function App() {
  const [data, setData] = useState([]);
  const getInitialData = async () => {
    try {
      const response = await api.get("/");
      if (response?.data) {
        setData(response?.data?.data);
      }
    } catch (error) {
      message.error("Some error occured in Server");
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <div>
      <Table dataSource={data} columns={columns} pagination={{ defaultPageSize: 5 }} />
    </div>
  );
}

export default App;
